import React from 'react';
import Navbar from '../components/Navbar';
import FirstSection from '../components/FirstSection';
import SecondSection from '../components/SecondSection';
import { motion } from "framer-motion"
import ThirdSection from '../components/ThirdSection';
import ThreeD from "../components/3d.jsx"
import Spline from '@splinetool/react-spline';
import bgdb from "../assets/images/ppp.png"
import styled from 'styled-components';
import LottieAnimation from '../lottie/Firstanimation.jsx';
import bg from "../assets/images/stone.jpg"

const AppContainer = styled.div`
  width: 100vw;
  height: 120vh;
  background-image: url(${bg});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; 
`;

const Dashboard = () => {
  return (
    <div className='relative ' >
      <AppContainer>
        <Navbar/>
        <FirstSection /> 
        </AppContainer>
        <SecondSection/>
        <ThirdSection/>
    </div>
  );
}

export default Dashboard;
