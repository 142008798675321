import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import axios from 'axios';
import { CiUser } from "react-icons/ci";

const Container = styled.div`
  box-shadow: none;
  background-color: rgba(111, 133, 147, 0.1);
  border-radius: 20px;
  margin-top: 20px;
  margin-left: 40px;
  padding-bottom: 120px;
  padding: 60px;
`;

const FormContainer = styled.div`
  display: flex;
  background-color: #fff;
  padding: px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
  border-radius: 15px;
  flex-direction: column;
`;

const FormField = styled.div`
  margin-bottom: 25px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 22px;
  margin-bottom: 25px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  color: #194569;
  margin-bottom: 10px;
  font-size: 18px;
`;

const Input = styled.input`
  width: 100%;
  color: #434343;
  padding: 12px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
`;

const Select = styled.select`
  width: 100%;
  color: #434343;
  padding: 12px;
  font-size: 16px;
  border: none;
  outline: none;
  border-bottom: 1px solid #ccc;
`;

const ButtonSec = styled.button`
  background-color: ${props => props.active ? '#194569' : '#fff'};
  color: ${props => props.active ? '#fff' : '#194569'};
  border: ${props => props.active ? 'none' : '2px solid #194569'};
  font-size: 20px;
  height: 70px;
  font-family: 'Poppins', sans-serif;
  cursor: pointer;
  border-radius: 25px;
  &:hover {
    box-shadow: ${props => props.active ? 'none' : 'rgba(0, 0, 0, 0.24) 0px 3px 8px'};
  }
`;

const ErrorMessage = styled.div`
  color: red;
  margin-top: 20px;
  font-size: 16px;
`;

const Styledh4 = styled.h4`
  color: #354959;
  font-family: "Raleway";
  font-weight: 700;
  text-align: center;
  margin-bottom: 40px;
  font-size: 24px;
`;

const ClassEdit = () => {
  const [classId, setClassId] = useState('');
  const [schoolId, setSchoolId] = useState('');
  const [gradeId, setGradeId] = useState('');
  const [branchId, setBranchId] = useState('');
  const [teacherId, setTeacherId] = useState('');
  const [grades, setGrades] = useState([]);
  const [branches, setBranches] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const token = localStorage.getItem('auth_token');

  useEffect(() => {
    if (!token) {
      setError('Token bulunamadı, lütfen giriş yapın.');
      return;
    }
  
    const fetchData = async (url, setData, dataKey) => {
      try {
        const response = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setData(response.data[dataKey]);
      } catch (err) {
        setError(`${url} API isteği başarısız: ${err.message}`);
        console.error(`${url} adresinden veri alınırken hata oluştu:`, err);
      }
    };
  
    fetchData('http://209.38.240.108:8000/api/grades', setGrades, 'grades');
    fetchData('http://209.38.240.108:8000/api/branches', setBranches, 'branches');
  }, [token]);
  
  useEffect(() => {
    if (!schoolId) return;
  
    const fetchTeachers = async () => {
      try {
        const response = await axios.get(`http://209.38.240.108:8000/api/school/${schoolId}/teachers`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTeachers(response.data.teachers || []);
        if (!response.data.teachers) setError('Öğretmen bulunamadı.');
      } catch (err) {
        setError(`Öğretmenler API isteği başarısız: ${err.message}`);
        console.error('Öğretmenler alınırken hata oluştu:', err);
      }
    };
  
    fetchTeachers();
  }, [schoolId, token]);
  
  const fetchClasses = async () => {
    setError('');
    if (!schoolId) {
      setError('Lütfen okul ID girin.');
      return;
    }
  
    try {
      if (classId) {
        console.log('Okul ID ve sınıf ID için sınıf verileri getiriliyor:', schoolId, 'sınıf ID:', classId);
        const response = await axios.get(`http://209.38.240.108:8000/api/school/${schoolId}/class/${classId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const classData = response.data.class;
        console.log('Sınıf verileri alındı:', classData);
        setGradeId(classData.grade_id);
        setBranchId(classData.branch_id);
        setTeacherId(classData.teacher_id);
      } else {
        console.log('Okul ID için sınıflar getiriliyor:', schoolId);
        const response = await axios.get(`http://209.38.240.108:8000/api/school/${schoolId}/classes`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const classesData = response.data.classes;
        console.log('Sınıf verileri alındı:', classesData);
  
        if (classesData && classesData.length > 0) {
          const classData = classesData[0];
          setClassId(classData.id);
          setGradeId(classData.grade_id);
          setBranchId(classData.branch_id);
          setTeacherId(classData.teacher_id);
        } else {
          setError('Bu okul için sınıf bulunamadı.');
        }
      }
    } catch (err) {
      setError(`Sınıf bilgileri alınamadı: ${err.message}`);
      console.error('Sınıf verileri alınırken hata oluştu:', err);
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      console.log('Güncellenen sınıf verileri gönderiliyor:', {
        classId,
        gradeId,
        branchId,
        teacherId,
        schoolId
      });
      const response = await axios.patch(`http://209.38.240.108:8000/api/school/${schoolId}/class/${classId}`, {
        grade_id: gradeId,
        branch_id: branchId,
        teacher_id: teacherId,
        school_id: schoolId
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setMessage(response.data.message);
      console.log('Sınıf başarıyla güncellendi:', response.data.message);
      console.log('Güncellenen sınıf verileri:', response.data); 
    } catch (err) {
      setError('Sınıf güncellenirken bir hata oluştu.');
      console.error('Sınıf güncellenirken hata oluştu:', err);
    }
  };  

  return (
    <Container>
      <FormContainer>
        <Styledh4>Düzenlemek istediğiniz sınıfın bilgilerini güncelleyin.</Styledh4>
        <form onSubmit={handleSubmit}>
          <FormField>
            <Label>Okul ID</Label>
            <InputContainer>
              <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
              <Input type="number" value={schoolId} onChange={(e) => setSchoolId(parseInt(e.target.value))} required />
            </InputContainer>
          </FormField>
          <FormField>
            <Label>Sınıf ID (İsteğe Bağlı)</Label>
            <InputContainer>
              <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
              <Input type="number" value={classId} onChange={(e) => setClassId(parseInt(e.target.value))} />
            </InputContainer>
          </FormField>
          <FormField>
            <Label>Sınıf Düzeyi (Grade ID)</Label>
            <InputContainer>
              <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
              <Select value={gradeId} onChange={(e) => setGradeId(parseInt(e.target.value))} required>
                <option value="">Seçiniz</option>
                {Array.isArray(grades) && grades.map((grade) => (
                  <option key={grade.id} value={grade.id}>
                    {grade.level}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </FormField>
          <FormField>
            <Label>Sınıf Şubesi (Branch ID)</Label>
            <InputContainer>
              <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
              <Select value={branchId} onChange={(e) => setBranchId(parseInt(e.target.value))} required>
                <option value="">Seçiniz</option>
                {Array.isArray(branches) && branches.map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.branch}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </FormField>
          <FormField>
            <Label>Öğretmen ID</Label>
            <InputContainer>
              <CiUser style={{ width: 24, height: 24, color: '#838383', marginRight: 15 }} />
              <Select value={teacherId} onChange={(e) => setTeacherId(parseInt(e.target.value))} required>
                <option value="">Seçiniz</option>
                {Array.isArray(teachers) && teachers.map((teacher) => (
                  <option key={teacher.id} value={teacher.id}>
                    {teacher.id}
                  </option>
                ))}
              </Select>
            </InputContainer>
          </FormField>
          <div style={{ display: 'flex', gap: '10px' }}>
            <ButtonSec type="button" onClick={fetchClasses}>Bilgileri Getir</ButtonSec>
            <ButtonSec type="submit">Güncelle</ButtonSec>
          </div>
        </form>
        {message && <p>{message}</p>}
        {error && <ErrorMessage>{error}</ErrorMessage>}
      </FormContainer>
    </Container>
  );
};

export default ClassEdit;
