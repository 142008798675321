import React from 'react';
import logo from "../../../assets/images/pencil.png"
const Navbar = () => {
  return (
    <div >
   <div className=' mx-2  my-3 py-5 flex gap-2 justify-center    ' >
    <img src={logo} style={{width:"40px",height:"40px"}}/>
    <div className="mx-3" style={{ zIndex: '999', fontFamily: "'Poppins', sans-serif", fontWeight: 'bold', fontSize: '22px', position: 'relative', lineHeight: '90%' }}>
          <span style={{ color: '#153c5b', position: 'absolute', fontSize: "22px", top: '0', left: '0' }}>
            ders
          </span>
          <br />
          <span style={{ color: '#5f84a2', fontSize: "22px" }}>durağı</span>
        </div>
   </div>

   </div>
  );
}

export default Navbar;
