import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import LoginPage from './views/admin/components/LoginPage';
import MyDrawer from './views/admin/components/DrawerCom';
import Home from './pages/Dashboard.jsx';
import OkulDashboard from "./views/school/pages/Dashboard.jsx";
import LoginStudent from './pages/LoginStudent.jsx';
import LoginTeacher from './pages/LoginTeacher.jsx';
import TeacherDashboard from "./views/teacher/pages/Dashboard.jsx";
import StaffLogin from './pages/LoginStaff.jsx';
import OgrenciDashboard from "./views/student/pages/Dashboard.jsx";
import PaymentIframeComponent from './components/Payment.jsx';
import Basket from './pages/Basket.jsx';
import TestManager from "./views/student/manager/TestManager.jsx"
import { useDispatch } from 'react-redux';
import { fetchUserInfo } from './redux/userSlice.js';





function App() {
  const [authToken, setAuthToken] = useState(localStorage.getItem('token'));

  const dispatch = useDispatch();

  useEffect(() => {
    const token = localStorage.getItem('token');
    console.log(token,"tokenn")
    if (token) {
      dispatch(fetchUserInfo(token));
    }
  }, [dispatch]);


  return (
    <div>
      <Router>
        <Routes>
          <Route path="/admin/login" element={<LoginPage setAuthToken={setAuthToken} />} />
          <Route path="/admin/*" element={authToken ? <MyDrawer setAuthToken={setAuthToken} /> : <Navigate to="/admin/login" />} />
          <Route path="/" element={<Home />} />
          <Route path="/student/login" element={<LoginStudent />} />
          <Route path="/staff/login" element={<StaffLogin />} />
          <Route path="/teacher/login" element={<LoginTeacher/>} />
          <Route path="/student" element={<OgrenciDashboard/>} />
          <Route path="/teacher" element={<TeacherDashboard/>} />
          <Route path="/sepet" element={<Basket/>}/>
          <Route path="/odeme" element={<PaymentIframeComponent iframeSrc={"https://sandbox-cpp.iyzipay.com/?token=0a42282f-ec9c-4dcd-afb4-19b64a819f47&lang=tr"} />} />
          <Route path="/staff" element={<OkulDashboard/>} />
          <Route path="/student/test" element={<TestManager/>} />

        </Routes>
      </Router>
    </div>
  );
}

export default App;
