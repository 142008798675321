
import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../data/Animation-pencil.json'; // JSON dosyanızın yolu

const LottieAnimation = () => {
  const defaultOptions = {
    loop: true, // Animasyonun sonsuz döngüde mi olacağını belirtir
    autoplay: true, // Animasyonun otomatik olarak başlamasını belirtir
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice' // Animasyonun nasıl görüntüleneceğini belirler
    }
  };

  return <Lottie options={defaultOptions} height={700} width={700} />;
};

export default LottieAnimation;
