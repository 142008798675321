import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import s1 from "../assets/images/girl1.png";
import s2 from "../assets/images/girl2.png";
import s3 from "../assets/images/girl3.png";
import styled from 'styled-components';
import bg from "../assets/images/stone.jpg"
import flag from "../assets/images/goal.png"

const StyledDiv = styled.div`
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  width: 25%;
  padding: 50px;
  height: 50vh;
`;


const Wrapper = styled.div`
  border-radius: 10px;
  display: flex;
  justify-content: center;
  height: 80px;
  position: relative;
  width: 80px;
  align-items: center;
  margin-bottom: 20px;
`;

const StyledTitle = styled.h6`
  font-weight: 800;
  font-size: 32px; 
  font-family: 'Onest';
  color: rgb(73, 85, 107);
 
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 18px; 
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 102%;
  margin-bottom: -1px;

  font-family: 'Rubik';
  background-color:rgb(73, 85, 107) ;
  color: rgb(255, 255, 255);
`;


const StyledP = styled.p`
  font-weight: 400;
  font-size: 18px;
  font-family: 'Onest';
  color: rgb(73, 85, 107);
  word-wrap: break-word;
`;

const Card = styled.div`
  height: 250px;
  width: 210px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  background-color: rgb(255, 255, 255);
  border: 5px solid rgb(73, 85, 107);
  border-radius: 10px;
  box-shadow: 10px 10px 0 rgb(54, 62, 79);
  justify-content: space-between; /* Elemanlar arası boşluk sağlar */
`;


const SecondSection = () => {

  return (
    <div style={{  paddingTop: "100px", zIndex: "-6" ,  backgroundImage: `url(${bg})`,backgroundPosition: "center",backgroundRepeat: "no-repeat",backgroundSize: "cover",width:"100vw",height:"100vh",display:"flex",justifyContent:"center"
 }} >
      <div className="relative w-1/3 ">
       
       <Card className='absolute top-[-15%] -left-3 z-0 -rotate-2 '> <img src={s1} style={{width:"150px" }} />  <Title>Her yerde eğitim</Title></Card>
       <Card className='absolute top-[45%] -left-16 z-10 -rotate-12' >  <img src={s2} style={{width:"150px" }} /> <Title>Yüksek başarı</Title></Card>
       <Card className='absolute top-[15%] left-32 z-20 rotate-12' >  <img src={s3} style={{width:"150px" }} /><Title>Güzel sonuçlar</Title> </Card>

      </div>
      <div className='w-1/3 block'>
   <img src={flag} style={{width:"70px",height:"70px",marginBottom:"20px"}} />
        <StyledTitle>Öncelikli Amacımız </StyledTitle>
        <StyledP> <br/> Öğrencilere yapay zeka ile kişiselleştirilmiş, kolay ve etkili bir öğrenme deneyimi sunmak. Chatbot'umuz sayesinde her öğrenci, ihtiyacına göre yönlendirmeler alır, anlamadığı konuları hızla tekrar eder ve kendi temposunda öğrenir. <br/><br/> Amacımız, her öğrencinin potansiyelini en iyi şekilde ortaya çıkarmak ve eğitimde fırsat eşitliği sağlamaktır</StyledP>
      </div>
  
    </div>
  );
};

export default SecondSection;
