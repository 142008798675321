import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CiUser } from "react-icons/ci";
import { FaUser } from "react-icons/fa";
import { IoCloudUploadOutline } from "react-icons/io5";
import { FaUserFriends } from "react-icons/fa";
import { useSelector } from 'react-redux';
import { TbCircleChevronDown } from "react-icons/tb";
import axios from 'axios';
import { LocationCityOutlined } from '@mui/icons-material';

const Container = styled.div`
  box-shadow: none;
  background-color: #fcfcfc;
  border-radius: 20px;
  margin-top: 10px;
  margin-left: 20px;
  min-height: 100vh;
`;
const Icon = styled.i`
  position: absolute;
  top: 18px;
  right: 25px;
  font-size: 23px;
`;
const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  margin-bottom: 20px;
`;

const ButtonSec = styled.button`
  background-color: ${props => props.active ? '#194569' : '#ffffff'};
  color: ${props => props.active ? '#ffffff' : '#194569'};
  border: ${props => props.active ? 'none' : '2px solid #194569'};
  font-size: 17px;
  letter-spacing: normal;
  height:60px;
  font-family: 'Poppins',sans-serif;
  cursor: pointer;
  width: 50%;
  border-radius: 20px;
  &:hover {
    box-shadow: ${props => props.active ? 'none' : 'rgba(0, 0, 0, 0.24) 0px 3px 8px;'};
  }
`;
const InputBox = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`;

const SelectField = styled.select`
  width: 100%;
  height: 55px;
  font-size: 16px;
  background: transparent;
  color: #4c4a70;
  padding: 0 20px;
  font-family: 'Poppins', sans-serif;
  border: 1px solid #acacbb;
  border-radius: 30px;
  outline: none;
  appearance: none;
`;
const FormContainer = styled.div`
  display: flex;
  background-color: rgb(255, 255, 255);
  padding: 50px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;  
  border-radius: 10px;
  flex-direction: column;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  font-weight: bold;
  color: #194569;
  margin-bottom: 5px;
`;

const Input = styled.input`
  width: 100%;
  color: #434343;
  padding: 8px;
  border: none;
  outline: none;

`;



const UploadContainer = styled.div`
  text-align: center;
  padding: 20px;
  background-color: white;
  border: 2px dashed #ccc;
  width: 70%;
`;

const UploadButton = styled.input`
  margin-top: 10px;
`;

const Styledh4 = styled.h4`
color: #354959;
  font-family: "Raleway";
  font-weight: 700;
  text-align: center;
  margin-bottom: 30px;
  font-size: 21px;
`;
const Students = () => {
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const schoolId = useSelector((state) => state.user.userInfo.staff.school_id);
  const cityId = useSelector((state) => state.user.userInfo.staff.school.city_id);
  const [gradeLevelId, setGradeLevelId] = useState('');
  const [classes, setClasses] = useState([]);
  const [classId, setClassId] = useState('');
  const token = localStorage.getItem('token');

  const toggleMode = () => {
    setIsBulkUpload(!isBulkUpload);
  };

  const [student, setStudentData] = useState({
    first_name: '',
    last_name: '',
    phone: '',
    email: '',
    password: '',
    city_id: cityId,
    grade_id: gradeLevelId,
    kvkk: '',
    type: "institution",
  });

  useEffect(() => {

    if (schoolId) {
      fetch(`https://api.robark.com.tr/api/school/${schoolId}/all-classes`)
        .then(response => response.json())
        .then(data => {
          if (data.status) {
            setClasses(data.classes || []);
          } else {
            console.error('Sınıflar alınamadı:', data.message);
          }
        })
        .catch(error => console.error('Sınıflar yüklenirken hata oluştu:', error));
    }
  }, [schoolId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setStudentData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSelectChange = (e) => {
    const selectedClassId = e.target.value;
    const selectedClass = classes.find((cls) => cls.class_id.toString() === selectedClassId);

    if (selectedClass) {
      setClassId(selectedClassId);
      const selectedGradeLevelId = selectedClass.grade_level.id;
      setGradeLevelId(selectedGradeLevelId);
      setStudentData((prevState) => ({
        ...prevState,
        grade_id: selectedGradeLevelId
      }));
      console.log("Güncellenen grade_id:", selectedGradeLevelId);
    } else {
      console.error("Seçilen sınıf bulunamadı");
    }
  };

  const handleSubmit = async () => {
    if (!classId) {
      console.error('Sınıf seçilmedi');
      // Kullanıcıya bir sınıf seçmesi gerektiğini bildiren bir hata mesajı gösterilebilir
      return;
    }

    try {
      const response = await axios.post(
        `https://api.robark.com.tr/api/school/${schoolId}/class/${classId}/student`,
        student,
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Headers': '*'
          }
        }
      );
      console.log('API response:', response.data);
    } catch (error) {
      console.error('Error posting student data:', error);
    }
  };




  return (
    <Container className='px-44 py-32'>
      <ButtonGroup>
        <ButtonSec active={!isBulkUpload} onClick={toggleMode}>
          Öğrenci Ekleme <FaUser style={{ width: "15px", marginLeft: "10px", display: "inline" }} />
        </ButtonSec>
        <ButtonSec active={isBulkUpload} onClick={toggleMode}>
          Toplu Öğrenci Ekleme <FaUserFriends style={{ width: "20px", marginLeft: "10px", display: "inline" }} />
        </ButtonSec>
      </ButtonGroup>

      {!isBulkUpload ? (
        <FormContainer>


          <Styledh4>Eklemek istediğiniz öğrencinin bilgilerini girin.</Styledh4>
          <FormField>
            <Label>Adı</Label>
            <InputContainer>
              <CiUser style={{ width: "20px", height: "20px", color: "#838383", marginRight: "10px" }} />
              <Input type="text" name="first_name" value={student.first_name} onChange={handleInputChange} placeholder="Ayşe" />
            </InputContainer>
          </FormField>
          <FormField>
            <Label>Soyadı</Label>
            <InputContainer>
              <CiUser style={{ width: "20px", height: "20px", color: "#838383", marginRight: "10px" }} />
              <Input type="text" name="last_name" value={student.last_name} onChange={handleInputChange} placeholder="Yılmaz" />
            </InputContainer>
          </FormField>
          <FormField>
            <Label>Telefon Numarası</Label>
            <InputContainer>
              <CiUser style={{ width: "20px", height: "20px", color: "#838383", marginRight: "10px" }} />
              <Input type="text" name="phone" value={student.phone} onChange={handleInputChange} placeholder="555 555 55 55" />
            </InputContainer>
          </FormField>
          <FormField>
            <Label>Mail Adresi</Label>
            <InputContainer>
              <CiUser style={{ width: "20px", height: "20px", color: "#838383", marginRight: "10px" }} />
              <Input type="email" name="email" value={student.email} onChange={handleInputChange} placeholder="abc@gmail.com" />
            </InputContainer>
          </FormField>

          <FormField>
            <Label>Şifre</Label>
            <InputContainer>
              <CiUser style={{ width: "20px", height: "20px", color: "#838383", marginRight: "10px" }} />
              <Input type="password" name="password" value={student.password} onChange={handleInputChange} placeholder=" ***** " />
            </InputContainer>
          </FormField>
          <FormField>

          <Label>Sınıf</Label>
          <InputBox>
            <SelectField id="class" name="class" value={classId} onChange={handleSelectChange} required>
              <option value="" disabled hidden>
                Öğrenciyi hangi sınıfa eklemek istediğinizi seçin.

              </option>
              {classes.map(cls => (
                <option key={cls.class_id} value={cls.class_id}>
                  {cls.class_name}
                </option>
              ))}
            </SelectField>
            <Icon>
              <TbCircleChevronDown style={{ color: "#757484" }} />
            </Icon>
          </InputBox>
          </FormField>

          <FormField>
            <Label>Kvkk Onayı</Label>
            <InputContainer>
              <input
                type="checkbox"
                name="kvkk"
                checked={student.kvkk === 1}
                onChange={(e) => setStudentData(prevState => ({
                  ...prevState,
                  kvkk: e.target.checked ? 1 : 0
                }))}
              />
              <span style={{ marginLeft: '10px', color: '#838383' }}>Kvkk onaylıyorum</span>
            </InputContainer>
          </FormField>

          <ButtonSec onClick={handleSubmit}>Öğrenci Ekle</ButtonSec>
        </FormContainer>

      ) : (
        <div className='w-100' style={{ display: "grid", placeItems: "center", }}>

          <UploadContainer>
            <div>
              <IoCloudUploadOutline className='mx-auto' style={{ width: "80px", height: "70px", color: "#133049" }} />
            </div>
            <input
              type="file"
              name='gorseller'
              multiple
              style={{ display: "none" }}
              id="fileInput"
              accept="file/*"
            />
            <label htmlFor="fileInput" className="pickfoto"> <h6 style={{ textAlign: "center", color: "#16202e", fontFamily: "Raleway", cursor: "pointer", fontSize: "14px", marginTop: "5px" }}>
              Dosya seç
            </h6></label>
            <h5>Öğrencilerinizi toplu olarak yüklemek için öğrenci listesi içeren dosyanızı seçin.</h5>

            <button style={{ borderRadius: "20px", width: "min-content", backgroundColor: "#133049", color: "white" }} role="button"> Yükle</button>
          </UploadContainer>
        </div>


      )}
    </Container>
  );
};

export default Students;
