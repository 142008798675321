
import React from 'react';
import Lottie from 'react-lottie';
import animationData from '../data/firstanimation.json'; // JSON dosyanızın yolu

const LottieAnimation = () => {
  const defaultOptions = {
    loop:false, // Animasyonun sonsuz döngüde mi olacağını belirtir
    autoplay: false, // Animasyonun otomatik olarak başlamasını belirtir
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice' // Animasyonun nasıl görüntüleneceğini belirler
    }
  };

  return (
    <div style={{ position:"absolute",top:"0",left:"0" ,zIndex:"-5",height:"100vh",float:"right"}}>
     <Lottie options={defaultOptions} height={"135vh"} width={"100vw"}/>
    </div>
  );

};

export default LottieAnimation;
