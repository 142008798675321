import React, { useState } from "react";
import { useSpring, animated } from "react-spring";
import "../assets/styles/card.css";
import styled from "styled-components";
import { FaCircleChevronRight } from "react-icons/fa6";
import { useDispatch } from "react-redux";
import { addItem } from "../redux/cartSlice";
import { LuShoppingBasket } from "react-icons/lu";

const Button = styled.div`
  cursor: pointer;
  border:2px solid rgb(102, 102, 136);
  transition: box-shadow 0.3s;
  border-radius: 15px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
  }
`;

function Card({ content, title, id, price, image, piece }) {
  const dispatch = useDispatch();

  const handleAddToCart = () => {
    const product = { content, id, title, price, image, piece };
    dispatch(addItem(product));
  };

  const [show, setShown] = useState(false);
  const props3 = useSpring({
    opacity: 1,
    transform: show ? "scale(1.03)" : "scale(1)",
    boxShadow: show
      ? "0 20px 25px rgb(0 0 0 / 25%)"
      : "0 2px 10px rgb(0 0 0 / 8%)"
  });

  return (
    <animated.div
      className="card"
      style={props3}
      onMouseEnter={() => setShown(true)}
      onMouseLeave={() => setShown(false)}
    >
      <div className="image-container">
        <img src={image} alt={title} className="card-image w-[400px] " />
      </div>
      <h2 className="text-center" style={{ fontFamily: "Raleway" }}>{title}</h2>
      <p style={{ fontFamily: "Raleway" }}>{content}</p>
      <div className="btnn flex mx-auto">
        <Button onClick={handleAddToCart} className="py-3 px-5 flex gap-2">
          <span style={{ color: "rgb(102, 102, 136)", fontSize: "14px" }}>Sepete Ekle</span>
          <LuShoppingBasket style={{ width: "20px", height: "20px", color: "rgb(102, 102, 136)", display: "inline-block" }} />
        </Button>
      </div>
    </animated.div>
  );
}

export default Card;
