import React from 'react';
import bg from "../assets/images/ppp.png"
import icon from "../assets/images/resim.png"
import icon2 from "../assets/images/resim2.png"
import book from "../assets/images/book.png"
import ok from "../assets/images/arow.png"
import ok2 from "../assets/images/arrow2.png"

import { FaPlay } from "react-icons/fa6";

import styled from 'styled-components';
import LottieAnimation from "../lottie/LottieAnimation.jsx"
import { FaArrowRight } from "react-icons/fa6";

//green : #df91b9
//yellow: #e0cb5e
//green : #4fa6a8
const Button = styled.button`
  position: relative;
  cursor: pointer;
  padding: 20px 35px;
  display: flex;
  align-items: center;
  background-image: linear-gradient(15deg, #2a7b7d 0%, #8fe5e6 100%);
  border-radius: 35px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 25px 20px -20px;
  &:hover {
    background-image: linear-gradient(400deg, #2a7b7d 0%, #8fe5e6 100%);
  }
`;

const Styledh6 = styled.h6`
  font-weight: 600;
  font-size: 19px;
  font-family: Poppins;
  color: rgb(255, 255, 255);
  display: inline;
  cursor: pointer;
  position: relative;
  transition: color 0.3s ease; /* Renk geçişi için transition ekledik */
  text-decoration: none;
`;

const PlayButton = styled.div`
  display: inline-flex;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background: linear-gradient(to top, #d372a4 0%, #ffd2e9 100%);
  box-shadow: #d88ab3 0px 25px 20px -20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: transform 0.5s ;

  &:hover {
    transform: translateY(-4px);
}
`;
const PlayIcon = styled(FaPlay)`
  width: 30px;
  height: 30px;
  color: #ffffff;
  transition: width 0.5s height 0.5s;
  
  ${PlayButton}:hover & {


}
`;

const FirstSection = () => {
  return (
    <div className=' flex justify-evenly  h-screen'>
      <div className='flex relative justify-center pr-32  items-center mb-20'>
        <div className=' text-center md:text-left '>
          <div style={{ fontSize: "63px", fontWeight: "700", color: "#537070", fontFamily: "Poppins", position: "relative", lineHeight: "1.1" }}>
            {/* <div style={{ position: "absolute", bottom: "-10%", right: "-5%", zIndex: "-1" }}> <LottieAnimation /> </div> */}Yeni nesil <br /> online okulun </div>
          <p className='mt-5 pr-10' style={{ fontSize: "18px", fontFamily: "Poppins", fontWeight: "500", color: "#a2a9b6" }}>
            Öğrenme serüvenin için özel olarak hazırlanan <br /> interaktif dersler ve yapay zeka destekli araçlarla <br /> dolu bir dünya  seni bekliyor.
          </p>
          <div className='absolute top-[5%] right-[-15%]'><img src={ok} style={{ width: "200px", height: "200px", transform: "rotate(50deg)" }} /></div>
          <div className='absolute bottom-[5%] right-[-15%]'><img src={ok2} style={{ width: "200px", height: "200px", transform: "rotate(50deg)" }} /></div>

          <div style={{ display: "flex", alignItems: "center", gap: "90px", marginTop: "40px" }}>
            <Button> <Styledh6>Keşfet   <FaArrowRight style={{ width: "20px", height: "20px", color: "#ffffff", display: "inline", cursor: "pointer", marginBottom: "5px", marginLeft: "10px" }} />
            </Styledh6> </Button>
            <div className='flex gap-4' style={{ alignItems: "center" }}>
              <PlayButton>
              <PlayIcon />
              </PlayButton>
                 <span style={{ fontFamily: "Varela", color:"#455656",textDecoration: "underline", fontWeight: "600" }}>Videoyu izle</span>
            </div></div>

        </div>
      </div>

      <div className='relative z-10 '> <img src={bg} style={{ width: "full", height: "90%" }} />
      </div>
    </div>
  );
}
export default FirstSection;
