import React, { useEffect, useState } from 'react';
import Lessons from '../components/Lessons.jsx'; // Import the Lessons component
import Testler from '../components/Tests.jsx'; // Import the Testler component
import styled from 'styled-components';
import Sidebar from "../components/StudentDrawer.jsx";
import Homeworks from '../components/Homeworks.jsx';
import StudentDashboard from '../components/StudentDashboard.jsx';
import bg from "../../../assets/images/w11.jpg"
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../redux/userSlice.js';
import Chatbot from '../components/Chatbot.jsx';
const AppContainer = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  padding: 15px;
  overflow: hidden; 
 font-family: "Poppins", sans-serif;
`;

const ContentContainer = styled.div`
  transition: margin-left 0.3s;
  padding: 20px;
  width: 100%;
  height: 100vh; /* İçeriğin yüksekliği */
  overflow-y: auto; /* İçeriğin kaydırılmasını sağlar */
`;

const Dashboard = () => {
  const [selectedItem, setSelectedItem] = useState('anasayfa');
  const [drawerOpen, setDrawerOpen] = useState(true);


  const dispatch = useDispatch(); // Initialize dispatch
  const navigate = useNavigate(); // Initialize navigate

  const handleLogout = () => {
    localStorage.clear();
    dispatch(logout());
    navigate('/');
  };


  useEffect(() => {
    if (selectedItem === 'cikis') {
      handleLogout();
    }
  }, [selectedItem, handleLogout]); 


  const renderComponent = () => {
    switch (selectedItem) {
      case 'anasayfa':
        return <StudentDashboard/>;
      case 'iceriklerim':
        return;
      case 'odevler':
        return <Homeworks />;
      case 'testler':
        return <Testler />;
      case 'performans':
        return <Chatbot/>;
      case 'dersler':
        return <Lessons />;
      default:
        return;
    }
  };

  return (
    <AppContainer>
      <Sidebar selectedItem={selectedItem} setSelectedItem={setSelectedItem} />
      <ContentContainer drawerOpen={drawerOpen}>
        {renderComponent()}
      </ContentContainer>
    </AppContainer>
  );
}

export default Dashboard;
