import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid } from '@mui/material';
import ReactPlayer from 'react-player';
import styled from 'styled-components';
import { IoIosSearch } from "react-icons/io";
import { LuAlignLeft } from "react-icons/lu";
import { Player } from '@lordicon/react';
import { IoIosNotificationsOutline } from "react-icons/io";
import { HiOutlineUser } from "react-icons/hi2";
import { useDispatch, useSelector } from 'react-redux';
import Spline from '@splinetool/react-spline';
import marker from "../../../assets/images/marker.png"
import { RxPencil2 } from "react-icons/rx";
import { HiChevronRight } from "react-icons/hi2";
import { HiChevronDoubleRight } from "react-icons/hi2";
import { FaCircleChevronRight } from "react-icons/fa6";
import { Line } from 'react-chartjs-2';
import { CategoryScale, Chart } from "chart.js";
import { registerables } from 'chart.js';
import { data } from "../../admin/data/data2.js"
import success from "../../../assets/images/success.png"
import { fetchUserInfo } from '../../../redux/userSlice.js';

const Search = styled.input`
    width: 300px;
    height:50px;
    outline: none;
    background-color: #fafafa;
    padding-left: 30px;
    border-radius: 50px;
    font-family: 'Poppins', sans-serif;
    color: #384152;
    border: 2px solid #4d576a;
    &::placeholder {
      color: #384152;
      font-family: 'Poppins', sans-serif;
      font-size: 15px;
    }
`;

const Styledh6 = styled.h6`
  font-weight: 600;
  font-size: 23px;
  font-family: 'Rubik', sans-serif;
  color: #4c5565;
  display: inline;
  text-decoration: none;
`;

const Container = styled.div`
  display: flex;
  margin-top: 20px;
  width: 100%;
`;

const LeftDiv = styled.div`
  width: 65%; /* 3'te 2'si */
  padding: 10px;
`;

const RightDiv = styled.div`
  width: 35%; /* 3'te 1'i */
  padding: 10px;
`;

const Title = styled.h6`
  font-family: "Poppins";
  font-weight: 500;
  color: #4c5565;
  font-size: 20px;
`;


const Button = styled.button`
  cursor: pointer;
  padding: 15px;
  padding-left: 20px;
  font-weight: 600;
  padding-right: 20px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  border-radius: 30px;
  color: #ffffff;  
  background-image: linear-gradient(120deg, #ff867b 0%, #e182f4 100%);
`;


const AssignmentContainer = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer ;
  background-color: white;
`;

const AssignmentTitle = styled.h3`
  margin: 0;
  font-family: "Poppins";
  font-weight: 600;
  color: #4c5565; 
`;

const AssignmentDetail = styled.p`
  margin: 5px 0;
  font-family: "Poppins";

  color: #555;
`;

const Styledh4 = styled.h4`
  font-family: "Raleway";
  font-weight: 800;
  font-size: 24px;
  margin-left: 5px;
`;

const StyledButton = styled.button`
  background-color: transparent;
  color: #34405e;
  font-weight: 500;
  font-size: 14px;
  font-family: Poppins;
  height: 45px;
  margin-top: 15px;
  border: none;
  cursor: pointer;
  &:hover {
    background-image: linear-gradient(120deg, #ff867b 0%, #e182f4 100%);
    border-radius: 15px;
  }
  &.active {
    background-image: linear-gradient(120deg, #ff867b 0%, #e182f4 100%);
    border-radius: 15px;

  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;




const StudentDashboard = () => {

  const user = useSelector((state) => state.user);
  const { userInfo, status, error } = user;

  console.log("userinfosuuuu ", user)
  console.log("statuss",status)

  if (status === 'loading') {
    return <div>Yükleniyor...</div>;
  }
  const assignments = [
    {
      id: 1,
      subject: "Matematik",
      topic: "Denklemler",
      dueDate: "01.03.2024",
      description: "İki bilinmeyenli denklemler konusunda 10 adet soru çözümü yapınız."
    },
    {
      id: 2,
      subject: "Türkçe",
      topic: "Cümle Yapısı",
      dueDate: "01.03.2024",
      description: "Cümle ögelerini inceleyerek 5 adet örnek cümle kurunuz."
    },

  ];


  return (
    <div className='px-5'>
      <div className='flex justify-between items-center'>
        <Styledh6>    <LuAlignLeft style={{ width: "25px", height: "25px", color: "#384152", display: "inline", marginRight: "10px" }} />
          Anasayfam</Styledh6>
        <div className='flex items-center'>
          <form action="#" method="post" style={{ display: "inline", position: "relative", marginRight: "30px" }}>
            <Search type="text" name="search" placeholder="Buradan ara" />
            <IoIosSearch style={{ width: "25px", height: "25px", color: "#384152", position: "absolute", top: "25%", right: "20px" }} />
          </form>

          <IoIosNotificationsOutline style={{ width: "30px", height: "30px", color: "#4a5467", display: "inline", marginRight: "25px", cursor: "pointer" }} />
          <HiOutlineUser  style={{ width: "30px", height: "30px", color: "#4a5467", display: "inline", cursor: "pointer" }} />

        </div>
      </div>


      <Container>
        <LeftDiv>
          <div className=' h-52 bg-[#fcfcfc] rounded-3xl flex relative' style={{    border:" 1px solid #ddd"}}>
            <div className='p-7 '>  <div className='relative'> <Title> Selam {userInfo.student.first_name} ! <span style={{ fontWeight: "700" }}> </span>, <br /> bugün hangi konuyu keşfetmek istersin?</Title> <img src={marker} style={{ position: "absolute", top: "0", right: "18%", width: "130px" }} /></div>
              <Button>Derslere Git <FaCircleChevronRight style={{ width: "35px", height: "35px", color: "#ffffff", display: "inline", marginLeft: "10px" }} /></Button>
            </div>
            <div>    <Spline scene="https://prod.spline.design/9FzeIV4PtxXyucZ9/scene.splinecode" style={{ position: "absolute", bottom: "0", right: "0", height: "140%", width: "40%", zIndex: "111" }} />
            </div>
          </div>

          <div className='flex' >
            <div className='w-full bg-[#fcfcfc] mt-8  p-7 mr-5 rounded-3xl' style={{    border:" 1px solid #ddd"}}>
              <div className='flex justify-between align-middle mx-3 mb-10'>
                <Styledh4 style={{ color: "#34405e" }}>Performansım</Styledh4>

              </div>
              <Line
                data={data}
                options={{
                  scales: {
                    x: {
                      type: 'category'
                    }
                  }
                }}
              />
              <StyledButton className='px-7' >
                *Çözülen testlere göre hesaplanır.
              </StyledButton>
            </div>

          </div>
        </LeftDiv>
        <RightDiv>
          <div className=' bg-[#fcfcfc] rounded-3xl relative p-7' style={{    border:" 1px solid #ddd"}}>
            <div className='flex justify-between mb-3'>    <Title style={{ fontWeight: "700" }}> Ödevlerim  </Title>  <span style={{ fontSize: "14px", cursor: "pointer" }}>Hepsini gör<HiChevronDoubleRight style={{ width: "15px", height: "15px", color: "#4a5467", cursor: "pointer", display: "inline" }} /> </span> </div>
            {assignments.map(assignment => (
              <AssignmentContainer key={assignment.id}>
                <div>
                  <div style={{ backgroundImage: "linear-gradient(120deg, #ff867b 0%, #e182f4 100%)", borderRadius: "50%", width: "min-content", padding: "13px" }} ><RxPencil2 style={{ width: "25px", height: "25px", color: "#ffffff", cursor: "pointer", display: "inline" }} /></div>
                </div>
                <div>
                  <AssignmentTitle>{assignment.subject} - {assignment.topic}</AssignmentTitle>
                  <AssignmentDetail>{assignment.dueDate}</AssignmentDetail>
                </div>
                <div><HiChevronRight style={{ width: "20px", height: "20px", color: "#4a5467", display: "inline" }} /></div>
              </AssignmentContainer>
            ))}

          </div>
        </RightDiv>
      </Container>

    </div>
  );
};

export default StudentDashboard;
